/** @jsx jsx */
import { jsx } from 'theme-ui'
import useMeta from '@h/use-meta'
import connectEntity from '@h/connect-entity'
import LogoCollection from '@uswitch/trustyle.logo-collection'
import ModuleWrapper from '@h/module-wrapper'
import ImgixImg from '@e/imgix-img.connected'

import { LinkWrapper } from '@e/link'

const Image = props => {
  const { image = {}, linkHref = '' } = props
  const meta = useMeta('collection', 'logos')

  return (
    <Choose>
      <When condition={linkHref}>
        <LinkWrapper href={linkHref}>
          <ImgixImg entity={image} {...meta.imageProps} />
        </LinkWrapper>
      </When>
      <Otherwise>
        <ImgixImg entity={image} {...meta.imageProps} />
      </Otherwise>
    </Choose>
  )
}

const ConnectedImage = connectEntity()(Image)

const CollectionImage = props => (
  <ModuleWrapper>
    <LogoCollection
      logoImage={
        <For each='entry' of={props.entries} index='i'>
          <ConnectedImage key={i} entity={entry} />
        </For>
      }
    ></LogoCollection>
  </ModuleWrapper>
)

export default CollectionImage
